<!-- src/components/LoginForm.vue -->
<template>
  <div id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-80">
    <div v-if="loginForm" class="bg-white p-8 rounded shadow-md w-96">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Login</h2>
      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-600">Email Address</label>
          <input v-model="email" type="email" id="email" name="email"
                 class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-600">Password</label>
          <input v-model="password" type="password" id="password" name="password"
                 class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>

        <div class="text-center">
          <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              :class="{ 'opacity-50 cursor-not-allowed': loading }"
              @click="simulateLoading"
          >
            <span v-if="!loading">Submit</span>
            <svg v-if="loading" class="animate-spin inline-block h-5 w-5 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.008 8.008 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.963 7.963 0 0112 4v8h8zm-2 5.291c1.865-2.114 3-4.896 3-7.938h-4v8l3-2.647z"></path>
            </svg>
            <span v-if="loading">Loading...</span>
          </button>
          <br/><br/>
          <span class="text-right" style="color: #1a82fb; cursor: pointer" @click="loginForm = false">Quick Registration <i style="font-size: 16px; cursor: pointer" class="fa fa-arrow-circle-right text-blue-500" aria-hidden="true"></i></span>

        </div>

        <br/>
        <div v-if="errorMessage && Object.keys(errorMessage).length > 0" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <button @click="errorMessage = null" class="absolute top-0 right-0 px-4 py-3 focus:outline-none">
            <svg class="h-6 w-6 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <ul>
            <li v-for="(errors, field) in errorMessage" :key="field" class="mt-1">
              <ul class="list-disc list-inside">
                <li style="font-size: 12px; font-family: 'Segoe UI, Arial, sans-serif',serif" v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <div v-if="!loginForm" class="bg-white p-8 rounded shadow-md w-96">
      <i style="font-size: 16px; cursor: pointer" class="fa fa-arrow-circle-left text-blue-500" aria-hidden="true" @click="loginForm = true"></i>
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Quick Registration</h2>
      <form @submit.prevent="registrationForm">
        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-600">Church Name</label>
          <input v-model="name" type="text" id="name" name="name" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-600">Email Address</label>
          <input  v-model="email" type="email" id="email" name="email"
                 class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-600">Password</label>
          <input v-model="password" type="password" id="password" name="password"
                 class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>

        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-600">Confirm Password</label>
          <input v-model="password_confirmation" type="password" id="password" name="password"
                 class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>

        <div class="text-center">
          <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              :class="{ 'opacity-50 cursor-not-allowed': loading }"
              @click="simulateLoading"
          >
            <span v-if="!loading">Register</span>
            <svg v-if="loading" class="animate-spin inline-block h-5 w-5 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.008 8.008 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.963 7.963 0 0112 4v8h8zm-2 5.291c1.865-2.114 3-4.896 3-7.938h-4v8l3-2.647z"></path>
            </svg>
            <span v-if="loading">Loading...</span>
          </button>
        </div>

        <br/><br/>
        <div v-if="errorMessage && Object.keys(errorMessage).length > 0" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <button @click="errorMessage = null" class="absolute top-0 right-0 px-4 py-3 focus:outline-none">
            <svg class="h-6 w-6 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <ul>
            <li v-for="(errors, field) in errorMessage" :key="field" class="mt-1">
              <ul class="list-disc list-inside">
                <li style="font-size: 12px; font-family: 'Segoe UI, Arial, sans-serif',serif" v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AppService from "@/Services/AppService";

export default {
  name: 'LoginUser',
  props: ['token'],
  data() {
    return {
      loading: false,
      loginForm: true,
      errorMessage: '',

      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    };
  },
  methods: {
    install(){
      this.$emit('inst-event');
    },
    simulateLoading() {
      // Simulate loading for 2 seconds
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    async login() {
      this.loading =true;
      this.errorMessage = null
      try {
        const response = await axios.post(AppService.loginEndpoint, {
          email: this.email,
          password: this.password,
        });
        AppService.token = response.data.token;
        AppService.loggedIn = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMessage =  error.response.data.errors;
      }
    },

    async registrationForm() {
      this.loading =true;
      this.errorMessage = null
      try {
        const response = await axios.post(AppService.registerEndpoint, {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation

        });
        AppService.token = response.data.token;
        AppService.loggedIn = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMessage =  error.response.data.errors;
      }
    }
  }
};
</script>
